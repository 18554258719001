import { Grid, Hidden } from '@mui/material';
import { withStyles } from "@mui/styles";
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LeftPanel from '../../images/Left-Panel.png';
import LeftPanelDR from '../../images/Left-PanelDR_new.png';
import { getResource } from '@survey/common/dist/utilities/getResource';
import { getResources } from '@survey/common/dist/actions/resources.actions';
import BrowserDefaultLanguage from '@survey/common/dist/utilities/browserDefaultLanguage';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
  },
  panel: {
    alignItems: 'center',
    backgroundImage: `url(${LeftPanel})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    paddingBottom: '2rem',
    height: '100%',
    backgroundColor: '#1526a5',
    marginRight: '1em',
    minHeight: '2564px',
  },
  drpanel: {
    backgroundImage: `url(${LeftPanelDR})`,
    backgroundColor: '#005491',
  },
});

class Terms extends Component {

  componentDidMount() {
    const { resources } = this.props;
    isEmpty(resources) && this.props.getResources();
  }

  render() {
    console.log('props', this.props);
    const { classes, resources } = this.props;
    const isGermanTenderEnv = process.env.REACT_APP_ENVIRONMENT === 'germantender';

    return (
      <div className={classes.root}>
        {!isGermanTenderEnv && (
          <Grid container>
            <Hidden xsDown>
              <Grid item sm={3}>
                <div className={`${classes.panel} ${isGermanTenderEnv ? classes.drpanel : ''}`}></div>
              </Grid>
            </Hidden>
            <Grid item sm={9} xs={12}>
              <div dangerouslySetInnerHTML={{__html: getResource(resources, BrowserDefaultLanguage(), 'msgTermsOfUse2025', '')}}>

              </div>
            </Grid>
          </Grid>
        )}

        {isGermanTenderEnv && (
          <Grid container>
          <Hidden xsDown>
            <Grid item sm={3}>
              <div className={`${classes.panel} ${isGermanTenderEnv ? classes.drpanel : ''}`}></div>
            </Grid>
          </Hidden>
          <Grid item sm={9} xs={12}>
            <h1>Erklärung zur Barrierefreiheit</h1>
            <p>
              Wir (HIMSS) als Betreiber der Datenerhebungsplattform innerhalb des Konsortiums DigitalRadar Krankenhaus sind bemüht, die Plattform zur Erfassung der Daten zur Evaluierung des digitalen Reifegrades barrierearm zu gestalten.
            </p>
            <p>
              Wir verstehen Barrierefreiheit als fortlaufenden Prozess und werden unsere Online Erhebungsplattform laufend prüfen, anpassen und optimieren. Wir bitten um Verständnis, dass die Plattform noch nicht die optimale Benutzerfreundlichkeit aufweist.
            </p>
            <h2>1. Datenerhebungsplattform</h2>
            <p>
              Diese Erklärung zur Barrierefreiheit gilt für die Datenerhebungsplattform, die unter https://survey.digitalradar.himss.org gehostet ist. 
            </p>
            <h2>2. Bereitstellungsdatum der Plattform</h2>
            <p>
              Die Plattform wurde am 05.10.2021 bereitgestellt.
            </p>
            <h2>3. Letzte Aktualisierung</h2>
            <p>
              Diese Plattform wurde zuletzt am 05.10.2021 in wesentlichen Punkten inhaltlich überarbeitet.
            </p>
            <h2>4. Feedback und Kontaktangaben</h2>
            <p>
              Sind Ihnen Mängel beim barrierefreien Zugang zu Inhalten auf der Plattform aufgefallen oder haben Sie Anmerkungen sowie Fragen zum barrierefreien Zugang? Melden Sie sich gerne bei uns unter:
            </p>
            <ul style={{listStyleType: 'none'}}>
              <li><b>Konsortium DigitalRadar</b></li>
              <li>vertreten durch HIMSS Europe GmbH/inav – privates Institut für angewandte Versorgungsforschung GmbH/Lohfert &amp; Lohfert AG</li>
              <li>
                E-Mail: <a href="mailto:info@digitalradar-krankenhaus.de">info@digitalradar-krankenhaus.de</a>
              </li>
            </ul>
            <h2>5. Durchsetzungsverfahren</h2>
            <p>
              Beim Beauftragten der Bundesregierung für die Belange von Menschen mit Behinderungen ist eine Schlichtungsstelle gem. § 16 BGG eingerichtet worden.
            </p>
            <p>
              Per Antrag kann <a href="https://www.behindertenbeauftragter.de/DE/SchlichtungsstelleBGG/SchlichtungsstelleBGG_node.html">hier</a> ein Schlichtungsverfahren eingeleitet werden.
            </p>
            <p>
              Die Schlichtungsstelle nach § 16 BGG hat die Aufgabe, Streitigkeiten zwischen Menschen mit Behinderungen und öffentlichen Stellen zum Thema Barrierefreiheit außergerichtlich beizulegen. Anders als viele Gerichtsverfahren sind Schlichtungsverfahren kostenlos. Es muss kein Rechtsbeistand eingeschaltet werden.
            </p>
            <p>
              Dabei geht es nicht in erster Linie darum, Gewinner oder Verlierer zu finden, sondern gemeinsam mit Hilfe der Schlichtungsstelle den Konflikt zu lösen.
            </p>
            <p>
              Näheres zum Ablauf des Schlichtungsverfahrens finden Sie <a href="https://www.behindertenbeauftragter.de/DE/SchlichtungsstelleBGG/Schlichtungsverfahren/Schlichtungsverfahren_node.html">hier</a>.
            </p>
          </Grid>
        </Grid>
        )}
        
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {  
  console.log('mapStateToPropsTerms', state);
  return {
    resources: state.resources.get('resources')
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {getResources}
  )(withRouter(Terms))
);

// export default withStyles(styles)(
//   connect(
//     mapStateToProps,
//     {getResources}
//   )(Terms)
// );